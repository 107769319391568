<template>
  <div :class="extraClasses">
    <div class="mb-3" v-if="extendedStatus.userStatus === 'wait'">
      <div class="d-flex flex-column flex-lg-row align-items-center mb-4">
        <h5 class="text-center text-uppercase mb-lg-0">
          prima di procedere è necessario confermare la mail
        </h5>
        <b-button
          v-on:click="sendTokenRequest"
          size="xs"
          variant="primary"
          v-if="extendedStatus.userStatus === 'wait'"
          class="ms-lg-3"
        >
          <font-awesome-icon icon="envelope" /> RE-INVIA MAIL
        </b-button>
      </div>
    </div>
    <h5  v-if="!readOnly" class="text-center">
      Completa i tuoi dati anagrafici e accetta il contratto di utilizzo
      della piattaforma per ottenere il tuo badge, iniziare a collezionare feedback e aumentare
      il tuo scoring!
    </h5>
  </div>
</template>

<script>
import { extractErrorMessage } from '@/utils/validators';

export default {
  name: 'UserWaitMessage',
  props: {
    readOnly: Boolean,
    extraClasses: String,
  },
  computed: {
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
  },
  methods: {
    sendTokenRequest() {
      console.log('Send validation request');
      this.$confirm(
        {
          message: 'Vuoi richiedere la verifica della tua email?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('subject/sendTokenRequest').then(() => {
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
